.container {
  :global {
    .rct-scroll {
      overflow: hidden !important;
    }

    .rct-header-root {
      background: #edf2f7 !important;
      border-bottom: none !important;
    }

    .header-container .rct-calendar-header {
      border-top-left-radius: 5px !important;
      border-top-right-radius: 5px !important;
      border: 1px solid #e2e8f0 !important;
    }

    .selected-date {
      background: #08c8f6 !important;
      color: #fff !important;
    }

    .day-header .rct-dateHeader {
      color: #718096 !important;
      background: #f7fafc !important;
      font-weight: 400 !important;
      border-top: 1px solid #e2e8f0 !important;
      border-right: 1px solid #e2e8f0 !important;
      border-left: 1px solid #e2e8f0 !important;
      border-bottom: none !important;
    }

    .date-header .rct-dateHeader {
      background: #f7fafc !important;
      font-weight: 500 !important;
      font-size: 16px !important;
      border-top: none !important;
      border-right: 1px solid #e2e8f0 !important;
      border-left: 1px solid #e2e8f0 !important;
      border-bottom: none !important;
    }

    .rct-horizontal-lines .rct-hl-odd,
    .rct-horizontal-lines .rct-hl-even {
      background: #fff !important;
      border: 1px solid #e2e8f0 !important;
    }

    .rct-vertical-lines .rct-vl.rct-day-6,
    .rct-vertical-lines .rct-vl.rct-day-0 {
      border: 1px solid #e2e8f0 !important;
      border-right: none !important;
      background: rgba(237, 242, 247, 0.5) !important;
    }

    .rct-vertical-lines .rct-vl {
      border: 1px solid #e2e8f0 !important;
    }

    .rct-sidebar {
      border: none !important;
      background: #f7fafc !important;
    }

    .rct-sidebar-row {
      border-bottom: none !important;
    }

    .rct-sidebar .rct-sidebar-row.rct-sidebar-row-even,
    .rct-sidebar .rct-sidebar-row.rct-sidebar-row-odd {
      background: #edf2f7 !important;
    }
  }
}
